import React from 'react';
import classNames from 'classnames';
import { useStyles } from './styles';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { LinkModel } from '../types';
import defaultProfile from '../../../images/page-header/default_profile.png';

type Props = {
  links: LinkModel[];
  profileIconSrc: string;
  isProfileMenuOpen: boolean;
  setIsProfileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseLanguageMenu: () => void;
};

export const ProfileMenu = ({
  links,
  profileIconSrc,
  isProfileMenuOpen,
  handleCloseLanguageMenu,
  setIsProfileMenuOpen,
}: Props) => {
  const profileMenuRef = useOutsideClick(() => setIsProfileMenuOpen(false));

  const classes = useStyles();

  return (
    <div className={classes.profile}>
      <button
        className={classNames({ [classes.buttonOpenMenu]: isProfileMenuOpen })}
        data-btn='profile_btn'
        onClick={() => {
          setIsProfileMenuOpen(!isProfileMenuOpen);
          handleCloseLanguageMenu();
        }}
      >
        <img 
          data-btn='profile_btn' 
          src={profileIconSrc} 
          alt='img' 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultProfile
          }}
        />
      </button>
      <div ref={profileMenuRef} className={classes.profileMenu}>
        {links.map(x => (
            <a key={x.name} href={x.href} className={classes.profileMenuItem}>
              <img src={x.imageSrc} alt={x.name} />
              {x.name}
            </a>
        ))}
      </div>
    </div>
  );
};
