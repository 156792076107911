import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    marginLeft: 'auto',
    display: 'none',
    '@media (max-width: 1440px)': {
      display: 'block',
      marginLeft: '24px',
    },
  },
  burgerButton: {
    width: '26px',
    height: '20px',
    position: 'relative',
    marginLeft: 'auto',
    cursor: 'pointer',
    zIndex: 100000,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '26px',
      background: 'white',
      height: '3px',
      top: 0,
      borderRadius: '2px',
      transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '26px',
      background: 'white',
      height: '3px',
      bottom: '0',
      borderRadius: '2px',
      transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
    '& span': {
      position: 'absolute',
      top: '8.35px',
      width: '26px',
      background: 'white',
      height: '3px',
      borderRadius: '2px',
    },
  },
  burgerRotateButton: {
    '&:after': {
      transform: 'rotate(-405deg)',
      top: '9px',
    },
    '&:before': {
      transform: 'rotate(405deg)',
      bottom: '8px',
    },
  },
  menu: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    zIndex: 13050,
    overflow: 'hidden',
    transform: 'translateX(100%)',
  },
  menuActive: {
    transform: 'translateX(0)',
  },
  content: {
    width: '360px',
    height: '100%',
    background: '#3a28a7',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: '0',
    '& a': {
      color: 'white',
    },
    '&:after': {
      content: "''",
      width: 'calc(100% - 360px)',
      height: '100%',
      background: 'rgba(0,0,0,.4)',
      display: 'block',
      position: 'fixed',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      '&:after': {
        content: 'none',
        background: 'transparent',
      },
    },
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    marginTop: '64px',
    paddingBottom: '2px',
    '& a': {
      padding: '22px 24px',
      fontSize: '16px',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      gap: '24px',
      borderRadius: '8px',
      margin: '0 16px',

      '& img': {
        filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg) brightness(102%) contrast(103%)',
      }
    },
    '@media (max-width: 360px)': {
      '& a': {
        padding: '14px 24px',
      },
    },
  },
  authorizationContainer: {
    display: 'flex',
    padding: '16px',
    gap: '20px',
    marginTop: 'auto',
  },
  primaryButton: {
    color: 'white',
    borderRadius: '54px',
    fontSize: '15px',
    lineHeight: '28px',
    fontWeight: 600,
    width: '100%',
    height: '56px',
    border: '1px solid #65C15D',
    background: '#65C15D',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: '#97DE6B',
    },

    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  secondaryButton: {
    color: 'white',
    borderRadius: '54px',
    fontSize: '15px',
    lineHeight: '28px',
    fontWeight: 600,
    width: '100%',
    height: '56px',
    border: '1px solid #FFFFFF',
    background: 'none',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: '#7770FF',
      borderColor: '#7770FF',
    },

    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  subLink: {
    margin: '0 !important',
    padding: '15px 24px 15px 72px !important',
    '&$activeLink': {
      borderRadius: 0
    },
    '@media (max-width: 360px)': {
      padding: '12px 32px 12px 72px !important',
    },
  },
  activeLink: {
    background: '#6049EB',
  },
  accordionLink: {
    textDecoration: 'none',
    gap: '0 !important',
    '& > img': {
      marginRight: '24px',
    },
    '& > button': {
      marginLeft: '7px',
    },
  },
  openedAccordion: {
    outline: '2px solid #6049EB',
    borderRadius: '8px',
    backgroundColor: '#3A28A7',
    position: 'relative',
    '& + *[style="display: block;"]': {
      outline: '2px solid #6049EB',
      paddingTop: 15,
      marginTop: -6,
      marginLeft: 16,
      marginRight: 16,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      overflow: 'hidden'
    }
  },
  accordionSwitcher: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
  },
  languageSwitcher: {
    '& + *[style="display: block;"]': {
      marginBottom: 24,
      padding: '8px 0 0 0',
      overflow: 'visible'
    }
  },
  profileMenuContainer: {
    display: 'flex',
    padding: '16px 32px 16px 32px',
    background: '#2B1C88',
    alignItems: 'center',
    gap: '12px',
    '@media (max-width: 360px)': {
      padding: '10px 32px 10px 32px',
    },
  },
  profileImg: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    flexShrink: 0
  },
  profileUserName: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '6px',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  profileMenuLinksContainer: {
    flexDirection: 'column',
    background: '#2B1C88',
    borderBottom: '2px solid #6049EB',
    marginTop: 'auto',
    paddingTop: 16,
    paddingBottom: 16,
    '&[style="display: none;"]': {
      '& + $profileMenuContainer': {
        marginTop: 'auto'
      }
    }
  },
  profileMenuLink: {
    padding: '22px 24px',
    fontSize: '16px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
    margin: '0 16px',
    '& img': {
      filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg) brightness(104%) contrast(102%)',
    },
    '@media (max-width: 360px)': {
      padding: '14px 24px',
    },
  }
});
